module.exports = [{
      plugin: require('/Users/adrian/Sites/work/corporate.wealthmigrate.com/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#5500AD","theme_color":"#5500AD","display":"minimal-ui","icon":"src/assets/images/favicon.png"},
    },{
      plugin: require('/Users/adrian/Sites/work/corporate.wealthmigrate.com/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wealthmigrate","path":"/preview","previews":false,"refetchInterval":null},
    },{
      plugin: require('/Users/adrian/Sites/work/corporate.wealthmigrate.com/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W6Z8KPV"},
    },{
      plugin: require('/Users/adrian/Sites/work/corporate.wealthmigrate.com/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://corporate.wealthmigrate.com"},
    },{
      plugin: require('/Users/adrian/Sites/work/corporate.wealthmigrate.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
